<template>
	<div id="body">
		<!--    地图-->
		<div id="car_header">
		</div>
<!--   -->
		<el-tabs v-model="activeName" type="card"  style="background-color: #f7f7f7;padding-bottom: 300px">
			<el-tab-pane label="律师部分客户展示" name="first">
				<div id="OAbox">
					<div id="OAbox_box" v-for="(item,i) in LAWYER" :key="i">
            <div class="imgsBox">
              <img class="imgs"
                   :src="item.logo?item.logo:'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/MRLogo.png'"></img>
            </div>

						<p class="OAbox_box_name">
							{{item.Name}}
						</p>
					</div>
				</div>

			</el-tab-pane>
			<el-tab-pane label="工程建筑部分客户展示" name="second">
				<div id="OAbox">
					<div id="OAbox_box" v-for="(item,i) in ENGINEERING" :key="i">
            <div class="imgsBox">
              <img class="imgs"
                   :src="item.logo?item.logo:'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/MRLogo.png'"></img>
            </div>

						<p class="OAbox_box_name">
							{{item.Name}}
						</p>
						<!-- <img v-if="item.logo" class="imgs" :src="item.logo" />
						<div v-else class="Name">
							<p style="width: 60%">
								{{item.Name}}
							</p>
						</div> -->
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>

		<div id="var_bottom">
			<div id="var_bottom_box">
				<p id="cas_title">联系大总管专业顾问</p>
				<p id="sub">大总管让您管理更轻松</p>
				<div id="lin">

				</div>
				<p id="text">大总管专业顾问团队来自不同行业的专业人员</p>
				<p id="text">我们联合了国内著名的咨询公司，帮助数万企业轻松实现了管理信息化</p>
				<p id="text">现在，您可以直接联系我们，获得专业的咨询服务</p>
				<p id="texts">咨询热线</p>
				<p id="phonnum">4006182019</p>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	const axi = require('../assets/axi')

	let tableData = [
    {
      "Name": "北京变通律师事务所",
      "Address": "北京市大兴区金星路12号院2号楼616室",
      "TradID": 10,
      "lat": 39.762195,
      "lng":  116.350573,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%8C%97%E4%BA%AC%E5%8F%98%E9%80%9A%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg"
    },
    {
      "Name": "北京市中润律师事务所",
      "Address": "北京市朝阳区东三环北路38号院3号楼安联大厦1907-1909室",
      "TradID": 10,
      "lat": 39.921433,
      "lng":  116.463515,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%8C%97%E4%BA%AC%E5%B8%82%E4%B8%AD%E6%B6%A6%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80logo.svg"
    },
    {
      "Name": "北京江石律师事务所",
      "Address": "北京北京市朝阳区东大桥路8号SOHO尚都南塔1005室",
      "TradID": 10,
      "lat": 39.917867,
      "lng": 116.453156,
      "logo":'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%8C%97%E4%BA%AC%E6%B1%9F%E7%9F%B3%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png',

    },
    {
      "Name": "北京科尧律师事务所",
      "Address": "北京市朝阳区安贞安定路5号天圆祥泰大厦9层",
      "TradID": 10,
      "lat": 39.981515,
      "lng": 116.406387
    },
    {
      "Name": "北京匠诺律师事务所",
      "Address": "北京市通州区宋庄镇中国艺术品交易中心313",
      "TradID": 10,
      "lat": 39.941914,
      "lng": 116.726589,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/BJJN.jpg'

    },

    {
      "Name": "上海聚缘律师事务所",
      "Address": "上海上海市奉贤区上海市奉贤区金海公路6055号",
      "TradID": 10,
      "lat": 30.952119,
      "lng": 121.483328,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/f5cabf80ba85f8c91c4059ac8f9dfcf.jpg"
    },
    {
      "Name": "上海航泰律师事务所",
      "Address": "上海市浦东新区崂山路526号江苏大厦21楼B5",
      "TradID": 10,
      "lat": 31.227741,
      "lng":  121.523419,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E4%B8%8A%E6%B5%B7%E8%88%AA%E6%B3%B0%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png"
    },
   /* {
      "Name": "上海忠托律师事务所",
      "Address": "上海上海市青浦区漕盈路2500号907室907室",
      "TradID": 10,
      "lat": 31.172055,
      "lng": 121.096624,
      "logo":'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E4%B8%8A%E6%B5%B7%E5%BF%A0%E6%89%98%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png',
    },*/
    {
      "Name": "上海泓盛律师事务所",
      "Address": "上海上海市浦东新区浦东南路500号国家开发银行2709室",
      "TradID": 10,
      "lat": 31.237064,
      "lng": 121.510467,
      logo:'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E4%B8%8A%E6%B5%B7%E6%B3%93%E7%9B%9B%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg'
    },
    {
      "Name": "上海宝锦律师事务所",
      "Address": "上海市奉贤区南桥镇南方国际一大道1468号5楼百顺路口",
      "TradID": 10,
      "lat": 30.915187,
      "lng": 121.482261,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SHBJ.jpg'
    },
    {
      "Name": "上海沪森律师事务所",
      "Address": "上海市浦东新区浦东南路500号国家开发银行大厦40A",
      "TradID": 10,
      "lat": 31.242892,
      "lng": 121.517056,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SHHS.jpg'
    }, {
      "Name": "上海诚德律师事务所",
      "Address": "上海上海市闵行区昌达路27号2号楼201室",
      "TradID": 10,
      "lat": 31.060722,
      "lng": 121.514656,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SHCD.png"
    },
    {
      "Name": "上海诺可律师事务所",
      "Address": "上海市闵行区申滨南路1126号龙湖虹桥天街C栋311室",
      "TradID": 10,
      "lat": 31.191315,
      "lng": 121.312474,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E4%B8%8A%E6%B5%B7%E8%AF%BA%E5%8F%AF%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg"
    },
    {
      "Name": "国狮律师事务所(上海)",
      "Address": "上海市黄浦区中山南路 28 号久事大厦 23 楼",
      "TradID": 10,
      "lat": 31.225009,
      "lng": 121.499509,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%9B%BD%E7%8B%AE%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png"
    },
    {
      "Name": "上海沪盛律师事务所",
      "Address": "奉贤区望园南路 1518 号绿地未来中心 A1 号楼 1108 室",
      "TradID": 10,
      "lat": 30.915355,
      "lng": 121.489256,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%B2%AA%E7%9B%9B%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg"
    },
    {
      "Name": "上海天知澜律师事务所",
      "Address": "上海浦东新区浦东南路 256号1605",
      "TradID": 10,
      "lat": 31.239874,
      "lng": 121.508908,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E4%B8%8A%E6%B5%B7%E5%A4%A9%E7%9F%A5%E6%BE%9C%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg"
    },



    {
      "Name": "广东鹏浩律师事务所",
      "Address": "广东省深圳市宝安区三区中粮大厦26楼",
      "TradID": 10,
      "lat": 22.5569,
      "lng": 113.908658,
      "logo":'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%B9%BF%E4%B8%9C%E9%B9%8F%E6%B5%A9%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png',
    },
    {
      "Name": "广东谷都律师事务所",
      "Address": "广东省中山市中山港街道三乡法庭",
      "TradID": 10,
      "lat": 22.35815,
      "lng": 113.436395,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/GDGD.png"
    },
    {
      "Name": "广东华科律师事务所",
      "Address": "广州市越秀区广东大厦1楼",
      "TradID": 10,
      "lat": 23.131859,
      "lng": 113.267573,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/GDHK.png'

    }, {
      "Name": "广东金兆律师事务所",
      "Address": "东莞市南城街道宏二路1号蜂汇广场栋2001",
      "TradID": 10,
      "lat": 22.982503,
      "lng": 113.721754,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/GDJZ.jpg'
    },
    {
      "Name": "广东穗南律师事务所",
      "Address": "广州市荔湾区广州市荔湾区花地大道中66号荔胜广场北塔20层",
      "TradID": 10,
      "lat": 23.079105,
      "lng": 113.233118,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/GDSN.png"
    },
    {
      "Name": "广东大与德律师事务所",
      "Address": "广州市白云区石沙路石井工业区一横路1号106房",
      "TradID": 10,
      "lat": 23.223746,
      "lng": 	113.218659,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%B9%BF%E4%B8%9C%E5%A4%A7%E4%B8%8E%E5%BE%B7%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png"
    },
    {
      "Name": "广西至诚律师事务所",
      "Address": "广西南宁市锦春路15号",
      "TradID": 10,
      "lat": 22.800667,
      "lng": 108.367004,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%B9%BF%E8%A5%BF%E8%87%B3%E8%AF%9A%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png"
    },


    {
      "Name": "河南昭惠律师事务所",
      "Address": "郑州市金水区金水路219号盛润国际广场东座21层",
      "TradID": 10,
      "lat": 34.761508,
      "lng":  113.721115,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%B2%B3%E5%8D%97%E6%98%AD%E6%83%A0%E5%BE%8B%E6%89%80.jpg"
    },

    {
      "Name": "南阳明理法律咨询有限公司",
      "Address": "河南省南阳市宛城区仲景街道滨河大道1360号",
      "TradID": 10,
      "lat": 32.99609,
      "lng": 112.582879
    },
    {
      "Name": "河南永中律师事务所",
      "Address": "河南省安阳市文峰区紫薇大道",
      "TradID": 10,
      "lat": 36.100589,
      "lng": 114.365945
    },
    {
      "Name": "河南诺方律师事务所",
      "Address": "河南郑州市中原区中原中路220号裕达国贸A座16楼",
      "TradID": 10,
      "lat": 34.74698,
      "lng": 113.62212,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%B2%B3%E5%8D%97%E8%AF%BA%E6%96%B9.jpg'
    },
    {
      "Name": "河南善济律师事务所",
      "Address": "信阳市平桥区羊山新区新五大道与新二十二街交叉口叁号公馆1号楼一层、三层",
      "TradID": 10,
      "lat": 32.147142,
      "lng": 114.123234,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/HNSJ.png'
    },
    {
      "Name": "福建迅晟律师事务所",
      "Address": "福建省福州市晋安区横屿路21号东二环泰禾城市广场一期9#楼14层10办公",
      "TradID": 10,
      "lat": 26.092016,
      "lng":  119.335108,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E7%A6%8F%E5%BB%BA%E8%BF%85%E6%99%9F%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80logo.png"
    },
    {
      "Name": "福建莆湄律师事务所",
      "Address": "莆田市秀屿区交警大队(笏石派出所)马路正对面",
      "TradID": 10,
      "lat": 25.306014,
      "lng": 119.094986
    },
    {
      "Name": "福建莆秀律师事务所",
      "Address": "莆田市荔城区镇海南街100号(荔城交警大队旁)",
      "TradID": 10,
      "lat": 25.425797,
      "lng": 119.022236
    },

    {
      "Name": "云南云勇律师事务所",
      "Address": "云南省丽江市古城区吉祥东路三家村综合市场A37号",
      "TradID": 10,
      "lat": 26.861989,
      "lng": 100.233812,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/4f440f351fb665be0fe6bc845c63ea1.jpg',
    },
    {
      "Name": "云南临吉律师事务所",
      "Address": "云南省文山壮族苗族自治州文山市凤凰路开元广场C幢F栋56层",
      "TradID": 10,
      "lat": 23.388026,
      "lng": 104.230601,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/YNLJ.png"
    },
    {
      "Name": "云南甲冠律师事务所",
      "Address": "昆明市西山区滇池柏悦3栋30层",
      "TradID": 10,
      "lat": 25.03207,
      "lng": 102.666859,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/YNJG.png'
    },

    {
      "Name": "新疆鼎信旭业律师事务所",
      "Address": "新疆维吾尔自治区乌鲁木齐市水磨沟区立井街198号",
      "TradID": 10,
      "lat": 43.851208,
      "lng": 87.623517,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/b8ca9a83e81384a611fd6ba748c0a8f.png"
    },
    {
      "Name": "新疆百丰恒瑞律师事务所",
      "Address": "乌鲁木齐市水磨沟区南湖东路北五巷88号南湖俪苑小区珠峰大厦2楼",
      "TradID": 10,
      "lat": 43.831644,
      "lng": 87.628462,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/XJBFHR.jpg'
    },


    {
      "Name": "江西三江合(高安)律师事务所",
      "Address": "江西省宜春市高安市瑞阳新区财富中心十楼",
      "TradID": 10,
      "lat": 28.447535,
      "lng": 115.376926,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/JXSJH.png"
    },
    {
      "Name": "湖北言和律师事务所",
      "Address": "湖北省武汉市洪山区书城路15号亿胜科技大楼D718",
      "TradID": 10,
      "lat": 30.501587,
      "lng": 114.337856,
      "logo":'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%B9%96%E5%8C%97%E8%A8%80%E5%92%8C%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png',
    },
    {
      "Name": "湖北拙卓律师事务所",
      "Address": "湖北省恩施土家族苗族自治州恩施市金龙大道东方名都1号楼第7层",
      "TradID": 10,
      "lat": 30.319621,
      "lng": 109.508689,
      "logo":'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%8B%99%E5%8D%93%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg',
    },







     {
      "Name": "山西卫丰律师事务所",
      "Address": "运城市万荣县后土大道与华康南路交叉口东北40米",
      "TradID": 10,
      "lat": 35.414724,
      "lng": 110.85763,
      "logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SXWF.png"
    },
    // {
    // 	"Name": "河南常理律师事务所",
    // 	"Address": "河南省郑州市市辖区尚贤街6号利丰国际大厦13楼130313041305室",
    // 	"TradID": 10,
    // 	"lat": 34.789406,
    // 	"lng": 113.808434
    // },
    // {
    //   "Name": "贵州猪葛法律咨询有限责任公司",
    //   "Address": "贵州省贵阳市市辖区长岭街道长岭南路27号",
    //   "TradID": 10,
    //   "lat": 26.622405,
    //   "lng": 106.644293
    // },

    // {
    //   "Name": "linjia",
    //   "Address": "海南省海口市美兰区海口",
    //   "TradID": 10,
    //   "lat": 20.017425,
    //   "lng": 110.359765
    // },
    // {
    // 	"Name": "云南云勇律师事务所",
    // 	"Address": "云南省丽江市古城区祥和街道吉祥东路三家村综合市场A37号",
    // 	"TradID": 10,
    // 	"lat": 26.861989,
    // 	"lng": 100.233812
    // },

    {
      "Name": "安徽康强律师事务所",
      "Address": "安徽合肥市蜀山区置地广场D座9楼",
      "TradID": 10,
      "lat": 31.804991,
      "lng": 117.223775,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/AHKQ.jpg'

    }, {
      "Name": "成都港申律师事务所",
      "Address": "成都市双流区正通路516号2楼201号",
      "TradID": 10,
      "lat": 30.578363,
      "lng": 103.896523,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/CDGS.png'

    }, {
      "Name": "河北锦厦律师事务所",
      "Address": "邯郸市邯山区滏东南大街北明珠栋巷11号",
      "TradID": 10,
      "lat": 36.59082,
      "lng": 114.517621,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/HBJX.jpg'
    }, {
      "Name": "湖南商盟律师事务所",
      "Address": "长沙市雨花区明昇壹城三期商铺8栋220.221.222门面",
      "TradID": 10,
      "lat": 28.159367,
      "lng": 113.05287,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/HNSM.jpg'
    },
    {
      "Name": "江苏南京君劭律师事务所",
      "Address": "江苏省南京市鼓楼区南通路118号3号楼6楼",
      "TradID": 10,
      "lat": 32.080500,
      "lng": 118.732524,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E6%B1%9F%E8%8B%8F%E5%8D%97%E4%BA%AC%E5%90%9B%E5%8A%AD%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.png'
    },
    {
      "Name": "山东震训煊明律师事务所",
      "Address": "山东省青岛市崂山区合肥路857号北村新苑71号",
      "TradID": 10,
      "lat": 36.116798,
      "lng": 	120.434448,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%B1%B1%E4%B8%9C%E9%9C%87%E8%AE%AD%E7%85%8A%20%E6%98%8E%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg'
    },
   {
      "Name": "山东善思律师事务所",
      "Address": "山东省菏泽市东明县曙光路与工业路交叉口东50米路北",
      "TradID": 10,
      "lat": 35.285001,
      "lng": 115.108452,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SDSS.jpg'
    },
    {
      "Name": "山东丰鑫律师事务所",
      "Address": "山东省威海市环翠区连林岛路8号",
      "TradID": 10,
      "lat": 37.522294,
      "lng": 122.156380,
      "logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/%E5%B1%B1%E4%B8%9C%E4%B8%B0%E9%91%AB%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80.jpg'
    },





      {
			"Name": "上海晅合咨询管理有限公司",
			"Address": "上海市辖区奉贤区上海市奉贤区肖湾路511号2幢3层",
			"TradID": 7,
			"lat": 30.965965,
			"lng": 121.447592,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/hh.png"
		},
		{
			"Name": "上海凯神建设工程有限公司",
			"Address": "上海市辖区南汇区上海市浦东新区惠南镇南团公路398号",
			"TradID": 7,
			"lat": 31.031013,
			"lng": 121.763431,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/sk.png"
		},
		{
			"Name": "上海融垚建筑劳务有限公司",
			"Address": "上海市辖区静安区江场西路299弄4栋501",
			"TradID": 7,
			"lat": 31.295712,
			"lng": 121.441297,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/rg.png"
		},
		{
			"Name": "上海云再建筑材料有限公司",
			"Address": "上海市辖区黄浦区Shangai",
			"TradID": 7,
			"lat": 31.22986,
			"lng": 121.46924,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/YZ.png"
		},

		{
			"Name": "浙江深澜服装有限公司",
			"Address": "浙江宁波市镇海区镇骆东路1221号",
			"TradID": 1,
			"lat": 29.954161,
			"lng": 121.670836,
			"logo": 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/SLFS.png'
		},
		{
			"Name": "浙江皓阳新能源科技有限公司",
			"Address": "浙江省金华市婺城区北二环西路3439号浙师大网络经济创业园3号楼3113室",
			"TradID": 1,
			"lat": 29.128403,
			"lng": 119.637901,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/hy.png"
		},
		{
			"Name": "上海松江久福减震器有限公司",
			"Address": "上海市辖区嘉定区安亭镇方泰方德路201号",
			"TradID": 1,
			"lat": 31.315308,
			"lng": 121.231014,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/jf.png"
		},
		{
			"Name": "上海易工电器设备有限公司",
			"Address": "上海市辖区嘉定区方德路250弄23号",
			"TradID": 1,
			"lat": 31.312865,
			"lng": 121.229919,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/yg.png"
		},
		{
			"Name": "江苏华舜环境工程有限公司",
			"Address": "江苏南京市江宁区将军大道2号美之国",
			"TradID": 3,
			"lat": 31.953928,
			"lng": 118.774938,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/hs.png"
		},
		{
			"Name": "东派科技有限公司",
			"Address": "广东东莞市东莞市厚街镇",
			"TradID": 1,
			"lat": 22.936359,
			"lng": 113.670858,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/dp.png"
		},
		{
			"Name": "上海远承建筑装饰工程有限公司",
			"Address": "上海市辖区浦东新区龙东大道3000号1号楼A区902室",
			"TradID": 3,
			"lat": 31.223119,
			"lng": 121.632915,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/yc.png"
		},
		{
			"Name": "实景（上海）数码科技有限公司",
			"Address": "上海市辖区黄浦区雁荡路84号科学技术协会三号楼3115",
			"TradID": 7,
			"lat": 31.218899,
			"lng": 121.469522,
			"logo": "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/sj.png"
		},

		{
			"Name": "大总管（上海）科技有限公司",
			"Address": "上海上海市静安区江场西路299弄",
			"TradID": 13,
			"lat": 31.296815,
			"lng": 121.442259
		},
		{
			"Name": "银川市兴庆区冠捷装饰装修材料经营部",
			"Address": "宁夏回族自治区银川市兴庆区长城东路美德亨国际家居博览中心",
			"TradID": 13,
			"lat": 38.453907,
			"lng": 106.296191
		},
		{
			"Name": "大总管",
			"Address": "上海上海市静安区中环中铁时代广场",
			"TradID": 13,
			"lat": 31.296663,
			"lng": 121.442121
		},
		{
			"Name": "大总管电商物流",
			"Address": "上海市辖区黄浦区ddd",
			"TradID": 12,
			"lat": 31.22986,
			"lng": 121.46924
		},
		{
			"Name": "多多户益",
			"Address": "上海上海市松江区南乐路158号",
			"TradID": 12,
			"lat": 31.02483,
			"lng": 121.302949
		},

		{
			"Name": "上海市山东济宁商会",
			"Address": "上海市辖区浦东新区环龙路57号A座419",
			"TradID": 9,
			"lat": 31.206349,
			"lng": 121.528465
		},
		{
			"Name": "上海市河南商丘商会",
			"Address": "上海市辖区长宁区文正路4621号",
			"TradID": 9,
			"lat": 31.220367,
			"lng": 121.424624
		},
		{
			"Name": "大总管商协项目测试",
			"Address": "上海",
			"TradID": 9,
			"lat": 31.230416,
			"lng": 121.473701
		},
		{
			"Name": "红河州青年创业协会",
			"Address": "云南省红河哈尼族彝族自治州蒙自市蒙自市",
			"TradID": 9,
			"lat": 23.396201,
			"lng": 103.364905
		},
		{
			"Name": "大总管商超连锁店",
			"Address": "上海上海市静安区江场西路299弄",
			"TradID": 8,
			"lat": 31.296815,
			"lng": 121.442259
		},
		{
			"Name": "上海龙海通达建筑有限公司",
			"Address": "上海上海市崇明县庙镇宏海公路2050号",
			"TradID": 7,
			"lat": 31.71404,
			"lng": 121.347117
		},
		{
			"Name": "浙江铭新电力工程有限公司",
			"Address": "浙江省衢州市柯城区新宏东方广场3幢422室",
			"TradID": 7,
			"lat": 28.976539,
			"lng": 118.958
		},
		{
			"Name": "福建省土木建设实业有限公司安溪分公司",
			"Address": "福建省福州市晋安区福建省福州市晋安区居住主题桂湖南郡148栋106",
			"TradID": 7,
			"lat": 26.133462,
			"lng": 119.329395
		},
		{
			"Name": "山东碧水清泉泵业有限公司",
			"Address": "山东省德州市德城区德州东北城五金一区29号楼",
			"TradID": 7,
			"lat": 37.481417,
			"lng": 116.339873
		},
		{
			"Name": "漳州市中茂环境工程有限公司",
			"Address": "福建省漳州市漳浦县古雷港经济开发区疏港大道北1244号",
			"TradID": 7,
			"lat": 23.942302,
			"lng": 117.633574
		},
		{
			"Name": "上海岚覃电力工程有限公司",
			"Address": "上海上海市静安区保德路1238弄27号",
			"TradID": 7,
			"lat": 31.318102,
			"lng": 121.444177
		},
		{
			"Name": "杭州坤晟合建筑工程有限公司",
			"Address": "浙江省杭州市市辖区浦东新区祝桥镇卫民村11组808号",
			"TradID": 7,
			"lat": 29.937837,
			"lng": 120.16609
		},
		// {
		//   "Name": "上海华艺幕墙系统工程有限公司",
		//   "Address": "上海上海市静安区真华路1030弄76号1303室",
		//   "TradID": 7,
		//   "lat": 31.275963,
		//   "lng": 121.412967
		// },
		// {
		//   "Name": "上海苗渤智能科技有限公司",
		//   "Address": "上海上海市青浦区赵巷镇民实路8弄50号",
		//   "TradID": 7,
		//   "lat": 31.157933,
		//   "lng": 121.205691
		// },
		{
			"Name": "三亚丰森劳务服务有限责任公司",
			"Address": "海南省三亚市海棠区龙海村原龙海小学旧址",
			"TradID": 7,
			"lat": 18.373987,
			"lng": 109.741359
		},
		{
			"Name": "浙江英策电力工程有限公司",
			"Address": "浙江省衢州市市辖区荷三路143号201室",
			"TradID": 7,
			"lat": 28.935957,
			"lng": 118.879116
		},
		// {
		//   "Name": "李华清",
		//   "Address": "广东省深圳市市辖区光明区光明大街东区",
		//   "TradID": 7,
		//   "lat": 22.760048,
		//   "lng": 113.948923
		// },
		{
			"Name": "中禹建投控股集团有限公司",
			"Address": "陕西西安市市辖区未央区北二环西段9号A座3楼",
			"TradID": 7,
			"lat": 34.310566,
			"lng": 108.940884
		},
		// {
		//   "Name": "上海上煤燃气技术工程有限公司",
		//   "Address": "上海市辖区松江区梅家浜路1505弄69号",
		//   "TradID": 7,
		//   "lat": 31.049634,
		//   "lng": 121.229219
		// },

		{
			"Name": "上海洲浦物业管理有限公司",
			"Address": "上海市辖区浦东新区高行东靖路1831号先行大厦807室",
			"TradID": 7,
			"lat": 31.292684,
			"lng": 121.608198
		},

		{
			"Name": "上海崇晓电控设备有限公司",
			"Address": "上海市辖区静安区共和新路966号2404室",
			"TradID": 7,
			"lat": 31.25912,
			"lng": 121.46215
		},
		{
			"Name": "上海欧亚建筑安装工程有限公司",
			"Address": "上海市辖区浦东新区书院镇洼港村701号2号楼",
			"TradID": 7,
			"lat": 30.982444,
			"lng": 121.875998
		},
		// {
		//   "Name": "上海龙申水务有限公司",
		//   "Address": "上海市辖区闵行区苏召路1628号",
		//   "TradID": 7,
		//   "lat": 31.100258,
		//   "lng": 121.53796
		// },
		{
			"Name": "江西鹏沃建设工程有限公司",
			"Address": "江西抚州市南城县建昌镇五里庄村周家塅七组101",
			"TradID": 7,
			"lat": 27.573674,
			"lng": 116.641787
		},
		{
			"Name": "武汉健铭供水设备有限公司",
			"Address": "湖北武汉市东西湖区新城十三路58号",
			"TradID": 7,
			"lat": 30.61968,
			"lng": 114.10372
		},
		{
			"Name": "上海易兆自来水工程有限公司",
			"Address": "上海",
			"TradID": 7,
			"lat": 31.230416,
			"lng": 121.473701
		},
		// {
		//   "Name": "融恒智能科技有限公司",
		//   "Address": "上海市辖区静安区江场西路299弄",
		//   "TradID": 7,
		//   "lat": 31.296815,
		//   "lng": 121.442259
		// },
		// {
		//   "Name": "容川厨卫有限公司",
		//   "Address": "广东中山市中山市黄圃镇兴圃大道西103号",
		//   "TradID": 7,
		//   "lat": 22.712038,
		//   "lng": 113.327041
		// },
		{
			"Name": "上海根成建设集团有限公司",
			"Address": "上海市辖区松江区方泗公路18号1幢5楼",
			"TradID": 7,
			"lat": 31.120703,
			"lng": 121.275833
		},
		{
			"Name": "上海鄂冶建设工程集团有限公司",
			"Address": "上海市辖区浦东新区沪南公路5298号",
			"TradID": 7,
			"lat": 31.041226,
			"lng": 121.593735
		},
		{
			"Name": "加梯家（上海）品牌管理有限公司",
			"Address": "上海市辖区杨浦区国霞路259号909室",
			"TradID": 7,
			"lat": 31.314141,
			"lng": 121.505497
		},
		{
			"Name": "上海锦惠建设集团有限公司",
			"Address": "上海市辖区闵行区eerere",
			"TradID": 7,
			"lat": 31.112813,
			"lng": 121.381709
		},
		{
			"Name": "上海开本基础工程有限公司",
			"Address": "上海市辖区奉贤区大叶公路8188号8幢",
			"TradID": 7,
			"lat": 30.984513,
			"lng": 121.682136
		},
		{
			"Name": "上海宝力通基础工程有限公司",
			"Address": "上海市辖区宝山区化成路366弄8号504室",
			"TradID": 7,
			"lat": 31.379275,
			"lng": 121.499604
		},
		{
			"Name": "浙江禾沃智能科技有限公司",
			"Address": "浙江杭州市市辖区萧山经济技术开发区明星路371号2幢1729室",
			"TradID": 7,
			"lat": 30.203275,
			"lng": 120.248446
		},
		// {
		//   "Name": "皓阳新能源",
		//   "Address": "浙江金华市婺城区浙江省金华市师大街",
		//   "TradID": 7,
		//   "lat": 29.128257,
		//   "lng": 119.646295
		// },
		{
			"Name": "济南市长清区双涵工业设备厂",
			"Address": "山东济南市长清区五峰山街道",
			"TradID": 7,
			"lat": 36.445582,
			"lng": 116.846263
		},
		{
			"Name": "郑州碧之泉供水设备有限公司",
			"Address": "河南郑州市市辖区郭店镇高孟村黄金大道西段",
			"TradID": 7,
			"lat": 34.533282,
			"lng": 113.698105
		},

		// {
		//   "Name": "重庆林盟建筑安装有限公司",
		//   "Address": "重庆市辖区长寿区重庆市长寿区江南街道",
		//   "TradID": 7,
		//   "lat": 29.806999,
		//   "lng": 107.065458
		// },
		{
			"Name": "上海凯达安全技术工程有限公司",
			"Address": "上海市辖区嘉定区树屏路588弄43号",
			"TradID": 7,
			"lat": 31.409876,
			"lng": 121.259605
		},
		{
			"Name": "安徽宝慧建筑工程有限公司",
			"Address": "安徽合肥市市辖区方兴大道北",
			"TradID": 7,
			"lat": 31.725028,
			"lng": 117.334338
		},
		{
			"Name": "高辉不锈钢",
			"Address": "内蒙古包头市昆都仑区高油坊13排2号",
			"TradID": 7,
			"lat": 40.600771,
			"lng": 109.80866
		},
		{
			"Name": "辽宁盛柏霖建设工程有限公司",
			"Address": "辽宁沈阳市市辖区沈北新区蒲新路131号楼唐轩北廷112",
			"TradID": 7,
			"lat": 41.930852,
			"lng": 123.437949
		},
		{
			"Name": "上海河润消防工程科技有限公司",
			"Address": "上海市辖区宝山区上大路1258号",
			"TradID": 7,
			"lat": 31.310802,
			"lng": 121.385044
		},
		{
			"Name": "上海全逊新能源科技发展有限公司",
			"Address": "上海市辖区浦东新区向城路58号东方国际科技大厦四层",
			"TradID": 7,
			"lat": 31.223274,
			"lng": 121.530386
		},
		{
			"Name": "上海申浦劳务服务有限公司",
			"Address": "上海市辖区浦东新区上海浦东新区上南路3855号",
			"TradID": 7,
			"lat": 31.151241,
			"lng": 121.507192
		},
		{
			"Name": "山东中云诺建筑工程有限公司",
			"Address": "山东济南市历城区王舍人街道",
			"TradID": 7,
			"lat": 36.72081,
			"lng": 117.147097
		},
		// {
		//   "Name": "上海馨复实业有限公司",
		//   "Address": "上海市辖区杨浦区逸仙路205号",
		//   "TradID": 7,
		//   "lat": 31.296814,
		//   "lng": 121.489097
		// },
		{
			"Name": "围场满族蒙古族自治县辉煌混凝土建筑材料制造有限公司",
			"Address": "河北承德市围场满族蒙古族自治县围场镇金子二期9号楼",
			"TradID": 7,
			"lat": 41.975424,
			"lng": 117.749056
		},
		{
			"Name": "全家福金属制品厂",
			"Address": "浙江杭州市余杭区兰江街道万弓池路62号",
			"TradID": 7,
			"lat": 30.257166,
			"lng": 119.952984
		},
		{
			"Name": "商丘梁园篮球场",
			"Address": "河南商丘市市辖区文化路290号",
			"TradID": 6,
			"lat": 34.393626,
			"lng": 113.73599
		},
		{
			"Name": "融恒美发3店",
			"Address": "上海",
			"TradID": 6,
			"lat": 31.230416,
			"lng": 121.473701
		},
		{
			"Name": "融恒美发1店",
			"Address": "江西省赣州市宁都县1",
			"TradID": 6,
			"lat": 26.470996,
			"lng": 116.013621
		},
		{
			"Name": "融恒美发2店",
			"Address": "江苏省苏州市虎丘区",
			"TradID": 6,
			"lat": 31.294845,
			"lng": 120.566833
		},
		{
			"Name": "大总管报修",
			"Address": "上海",
			"TradID": 5,
			"lat": 31.230416,
			"lng": 121.473701
		},
		{
			"Name": "大管家汽修",
			"Address": "上海市辖区静安区江场西路299弄",
			"TradID": 4,
			"lat": 31.296815,
			"lng": 121.442259
		},
		{
			"Name": "上海晟渲企业发展中心（有限合伙）",
			"Address": "上海市辖区闵行区龙茗路98号3号楼202",
			"TradID": 3,
			"lat": 31.124498,
			"lng": 121.39152
		},

		// {
		//   "Name": "大总管（上海）科技有限公司",
		//   "Address": "上海市辖区静安区江场西路299弄4栋501",
		//   "TradID": 3,
		//   "lat": 31.295712,
		//   "lng": 121.441297
		// },
		// {
		//   "Name": "大总管（上海）科技有限公司",
		//   "Address": "上海静安区江场西路299弄",
		//   "TradID": 3,
		//   "lat": 31.296815,
		//   "lng": 121.442259
		// },
		{
			"Name": "东莞驰腾程云科技有限公司",
			"Address": "广东东莞市东莞市塘厦镇",
			"TradID": 3,
			"lat": 22.806924,
			"lng": 114.072521
		},

		// {
		//   "Name": "广州市睿集公益集团",
		//   "Address": "广东省惠州市博罗县广东省惠州市博罗县",
		//   "TradID": 3,
		//   "lat": 23.172899,
		//   "lng": 114.289496
		// },

		{
			"Name": "上海源坤科技有限公司",
			"Address": "上海市辖区奉贤区海湾镇五四公路4399号78幢",
			"TradID": 1,
			"lat": 30.873264,
			"lng": 121.676422
		},
		{
			"Name": "杭州前瑜科技有限公司",
			"Address": "浙江杭州市江干区杭州江干区天城路91号",
			"TradID": 1,
			"lat": 30.286419,
			"lng": 120.204395
		},
		{
			"Name": "融恒智能",
			"Address": "上海市辖区静安区江场西路299弄",
			"TradID": 1,
			"lat": 31.296815,
			"lng": 121.442259
		},

		{
			"Name": "联朔信息科技（上海）有限公司",
			"Address": "上海普陀区新村路423弄绿地威科广场23号1004",
			"TradID": 1,
			"lat": 31.266141,
			"lng": 121.428496
		},

		{
			"Name": "深圳精锐恒通电子有限公司",
			"Address": "广东深圳市南山区高新南五道金证大厦706室",
			"TradID": 1,
			"lat": 22.534952,
			"lng": 113.947617
		},
		{
			"Name": "银川市兴庆区鑫飞宇电子产品经销部 ",
			"Address": "宁夏银川市市辖区银川赛格电脑城负一层D035号",
			"TradID": 1,
			"lat": 38.465038,
			"lng": 106.280128
		},

		{
			"Name": "上海壕玛自动化工程有限公司",
			"Address": "上海市辖区奉贤区沿钱公路5601号1幢",
			"TradID": 1,
			"lat": 30.926722,
			"lng": 121.554784
		},
		{
			"Name": "上海惠得利工贸发展有限公司",
			"Address": "上海市辖区嘉定区上海市嘉定区宝园四路368号",
			"TradID": 1,
			"lat": 31.283633,
			"lng": 121.266236
		},
		{
			"Name": "马强贸易有限公司 ",
			"Address": "福建福州市市辖区万达广场",
			"TradID": 1,
			"lat": 26.032756,
			"lng": 119.2748
		},
		{
			"Name": "天津康元不锈钢水箱有限公司",
			"Address": "天津市辖区北辰区双口镇下河头",
			"TradID": 1,
			"lat": 39.196997,
			"lng": 117.017748
		},
		{
			"Name": "上海惠得利工贸发展有限公司",
			"Address": "上海上海市嘉定区宝园四路368号",
			"TradID": 1,
			"lat": 31.283633,
			"lng": 121.266236
		},
		{
			"Name": "柳州市荣获机电设备有限公司",
			"Address": "广西壮族自治区柳州市柳江县毅德城51栋111号",
			"TradID": 1,
			"lat": 24.247958,
			"lng": 109.376292
		},
		{
			"Name": "山东海纳农科",
			"Address": "山东德州市乐陵市汇源大街",
			"TradID": 1,
			"lat": 37.732988,
			"lng": 117.193384
		},
		{
			"Name": "佛山市钜形环保设备有限公司",
			"Address": "广东佛山市禅城区东平二路3号",
			"TradID": 1,
			"lat": 22.979645,
			"lng": 113.125375
		},
		{
			"Name": "上海侬早网络科技有限公司",
			"Address": "上海上海市松江区南乐路158号",
			"TradID": 1,
			"lat": 31.02483,
			"lng": 121.302949
		},
		{
			"Name": "奥利泵业（定州）有限公司",
			"Address": "河北省保定市定州市中兴西路",
			"TradID": 1,
			"lat": 38.510215,
			"lng": 114.981651
		},
		{
			"Name": "广西润弘机电设备有限公司",
			"Address": "广西壮族自治区柳州市市辖区市辖区",
			"TradID": 1,
			"lat": 22.815478,
			"lng": 108.327546
		},
		{
			"Name": "山东嘉汇水务环境科技有限公司",
			"Address": "山东省济南市长清区万归路五峰段16001号",
			"TradID": 1,
			"lat": 36.40906,
			"lng": 116.847804
		},
		{
			"Name": "济南科源水处理设备有限公司",
			"Address": "山东省济南市长清区五峰山街道办事处",
			"TradID": 1,
			"lat": 36.442711,
			"lng": 116.828811
		},
		{
			"Name": "杭州泓雨供水设备有限公司 ",
			"Address": "浙江省杭州市市辖区市辖区",
			"TradID": 1,
			"lat": 30.267446,
			"lng": 120.152791
		}
	]
	import footers from './FOOTER.vue'
	// 获取部分客户展示
	const OSS = require('ali-oss');
	const client = new OSS({
		// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
		region: 'oss-cn-shanghai',
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: 'LTAI5tEMVZjxdTHAYzsGbanj',
		accessKeySecret: '5QCCo038V8g9gzct7S9PlMSJyAiZtT',
		// 填写Bucket名称，例如examplebucket。
		bucket: "bxexample",
	});
	export default {
		components: {
			footers
		},
		computed: {
			// 律师客户
			LAWYER() {
				tableData.sort((a, b) => {
					const hasLogoA = Boolean(a.logo && a.logo !== '');
					const hasLogoB = Boolean(b.logo && b.logo !== '');

					// 如果 a 有 logo 并且 b 没有，则 a 应该在 b 前面
					if (hasLogoA && !hasLogoB) return -1;

					// 如果 b 有 logo 并且 a 没有，则 b 应该在 a 前面
					if (!hasLogoA && hasLogoB) return 1;

					// 如果两者都有或都没有 logo，则保持原有顺序不变
					return 0;
				});
				return tableData.filter(item => {
					return item.TradID == 10
				})
			},
			// 工程客户
			ENGINEERING() {
				return tableData.filter(item => {
					return item.TradID == 3 || item.TradID == 7
				})
			}
		},
		data() {
			return {
				activeName: 'first', //second  first
				list: [], //部分客户列表
				MAps: null,
				// tableData:[],
				// geocoder:null,
			}
		},
		created() {
			// tableData.forEach((item,index)=>{
			//   if(item.TradID == 10){
			//   }
			// })
			// this.CustomerList()
		},

		mounted() {
			// 绘制地图
			this.initMaps()
			// 部分客户列表
			try {
				let result = client.list({
					prefix: 'OfficialWebsite/LOGO/',
					// 设置正斜线（/）为文件夹的分隔符。
					delimiter: '/'
				}).then((result) => {
					let IMgarr = result.objects.slice(1, result.objects.length)
					for (let i = 0; i < IMgarr.length; i++) {
						IMgarr[i].name = IMgarr[i].name.split('OfficialWebsite/LOGO/')[1]
					}
					this.list = IMgarr
				})
			} catch (e) {
				console.log(e);
			}
		},
		methods: {
			randomHexColorCode() {
				let n = (Math.random() * 0xfffff * 1000000).toString(16);
				return "#" + n.slice(0, 6);
			},

			// 客户列表
			async CustomerList() {
				let {
					data
				} = await axi.post('Customer/GetCustomer', {})
				for (let i = 0; i < data.Dto.length; i++) {
					if (data.Dto[i].Address) {
						data.Dto[i].Address = data.Dto[i].Address.replace(
							/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g, '')

						let {
							lat,
							lng
						} = await this.getLocation(data.Dto[i].Address)
						data.Dto[i].lat = lat
						data.Dto[i].lng = lng
					}
				}
				this.tableData = data.Dto

				console.log(this.tableData)




			},
			// // 逆解析
			getLocation(Address) {
				console.log(Address.replace(/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g, ''))
				return new Promise((resolve, reject) => {
					this.geocoder.getLocation(Address, function(status, result) {
						if (status === 'complete' && result.geocodes.length) {
							let lnglat = result.geocodes[0].location
							resolve({
								lat: lnglat.lat,
								lng: lnglat.lng
							})
						} else {
							reject()
						}
					});
				})
			},


			initMaps() {
				// 配置地图的基本显示
				let that = this;
				that.MAps = new AMap.Map("car_header", {
					resizeEnable: true, //是否监控地图容器尺寸变化
					viewMode: '3D', //开启3D视图,默认为关闭
					buildingAnimation: true, //楼块出现是否带动画
					zoom: 5, //初始化地图层级
					center: [106.04124, 35.20204], //初始化地图中心点
					pitch: 15,
					rotation: 0,
					pitchEnable: true,
					jogEnable: true, //地图是否使用缓动效果
					zooms: [3, 20],
					showIndoorMap: false,
				});

				AMap.plugin([ //添加插件
					'AMap.ControlBar',
				], function() {
					// 添加 3D 罗盘控制
					that.MAps.addControl(new AMap.ControlBar({
						showZoomBar: false,
						showControlButton: true,
						position: {
							right: '10px',
							top: '10px'
						}
					}));
				}, );


				AMap.plugin(["AMap.ToolBar"], function() { //加载工具条
					var tool = new AMap.ToolBar();
					that.MAps.addControl(tool);
				});

				var infoWindow = new AMap.InfoWindow({
					offset: new AMap.Pixel(0, -30)
				});
				for (let i = 0; i < tableData.length; i++) {

					var marker = new AMap.Marker({
						title: '客户',
						position: new AMap.LngLat(tableData[i].lng, tableData[i].lat),
						icon: new AMap.Icon({
							image: 'https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/016-%E4%BB%A3%E7%90%86%E5%95%86.png',
							size: new AMap.Size(30, 30), //图标大小
							imageSize: new AMap.Size(30, 30)
						}),
						iconStyle: {
							src: '//webapi.amap.com/theme/v1.3/markers/b/mark_r.png',
							style: {
								width: '20px',
								height: '30px',
								color: "#FFF"
							}
						},
						offset: new AMap.Pixel(-13, -30),
					});

					AMap.event.addListener(marker, 'click', function() {
						infoWindow.open(that.MAps, marker.getPosition());
					});
					// 实例化信息窗体
					var content = [
						`<span style='font-size:14px;color:#00aaff;width:320px'> ${tableData[i].Name}</span>`,
						`<span style='font-size:11px;color:#8a8a8a;width:320px'>地址: ${tableData[i].Address}</span>`,
					];
					marker.content = content.join("<br/>");
					marker.on('click', markerClick);
					marker.emit('click', {
						target: marker
					});
					that.MAps.add(marker);
				}


				function markerClick(e) {
					infoWindow.setContent(e.target.content);
					infoWindow.open(that.MAps, e.target.getPosition());
				}

				that.MAps.setFitView();


				// let geolocation = location.initMap("map-container"); //定位
				// AMap.event.addListener(geolocation, "complete", result => {
				// 	console.log(result)
				// });


				// 点击选中搜索出来的信息时触发

				that.MAps.on('click', (ev) => {
					console.log(ev)
				})
			},
		}
	}
</script>

<style scoped lang="less">
	@media screen and (min-width: 720px) {

		//电脑  地图
		#car_header {
			width: 100%;
			height: 50vh;
		}

		//选项卡样式
		/deep/.el-tabs__nav-scroll {
			display: flex !important;
		}

		/deep/.el-tabs__nav {
			margin: 20px auto 0 !important;
		}

		/deep/.el-tabs__item {
			font-size: 20px !important;
			padding: 10px 20px !important;
			height: auto;
		}



		#OAbox {
			width: 80%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;

			#OAbox_box {
				width: 300px;
				height: 200px;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;
				align-content: center;
        align-content: space-around;

        box-sizing: border-box;
        background-color: #fff;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 1px #fff;

        .imgsBox{
          width: 280px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

        }

				img {
					width: auto;
          text-align: center;
					max-width: 280px;
					max-height: 90px;
					transition: all .8s;
				}

				.OAbox_box_name {
					width: 100%;
					text-align: center;
					font-weight: 600;
					letter-spacing: 3px;
					font-size: 20px;
					font-family: 仿宋;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
				}


				.Name {

					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 30px;

					width: 90%;
					height: 80%;
					background-color: #FFFFFF;
					box-shadow: 0px 4px 14px #e0dada;
					border-radius: 15px;
					transition: all .8s;
					color: #333;
				}

				.Name:hover {
					height: 82%;
				}
			}
		}

		//底部
		#var_bottom {
			width: 100%;
			height: 600px;
			//margin-top: 10%;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/jxbgc.jpg');
			background-size: 100% 100%;

			#var_bottom_box {
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, 0.4);
				text-align: center;
				padding-top: 5%;

				#cas_title {
					font-size: 60px;
					color: #fd3f31;
				}

				#sub {
					font-size: 30px;
				}

				#lin {
					width: 100px;
					height: 8px;
					background: #fd3f31;
					margin: 30px auto;
				}

				#text {
					font-size: 20px;
				}

				#texts {
					font-size: 26px;
					margin-top: 30px;
					color: #fd3f31;
				}

				#phonnum {
					font-size: 45px;
					color: #fd3f31;
				}
			}
		}
	}

	@media screen and (max-width: 720px) {

		//手机
		#car_header {
			width: 100%;
			height: 200px;
		}

		#OAbox {
			width: 100%;
			// height: 1000px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;

			#OAbox_box {
				width: 45%;
				height: 150px;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;
        align-content: space-around;

        box-sizing: border-box;

        background-color: #fff;
        margin: 10px;
        border-radius: 10px;
        box-shadow: 1px 1px 1px #fff;


				img {
					height: 80px;
					width: auto;
					max-width: 90%;
				}

				.OAbox_box_name {

					width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          //height: 50px;
					text-align: center;
					font-weight: 600;
					letter-spacing: 1px;
					font-size: 14px;
					font-family: 仿宋;
				}

				.Name {

					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					width: 90%;
					height: 80%;
					background-color: #FFFFFF;
					box-shadow: 0px 4px 14px #e0dada;
					border-radius: 15px;
					transition: all .8s;
					color: #333;
				}

			}
		}

		#var_bottom {
			width: 100%;
			height: 400px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/jxbgc.jpg');
			background-size: 100% 100%;

			#var_bottom_box {
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, 0.4);
				text-align: center;
				padding-top: 5%;

				#cas_title {
					font-size: 36px;
					color: #fd3f31;
				}

				#sub {
					font-size: 22px;
				}

				#lin {
					width: 40px;
					height: 8px;
					background: #fd3f31;
					margin: 10px auto;
				}

				#text {
					font-size: 14px;
				}

				#texts {
					font-size: 22px;
					color: #fd3f31;
				}

				#phonnum {
					font-size: 32px;
					color: #fd3f31;
				}
			}
		}
	}
</style>
